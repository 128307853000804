import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { CodeSnippet } from "../components/code-snippet";
import { PageLayout } from "../components/page-layout";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { gsap } from 'gsap';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CountyChart from "../components/charts/CountyChart";

import CircularProgress from '@mui/material/CircularProgress';

import { PageWrapper } from "./PageWrapper";
import HeyzineViewer from "../components/HeyZineViewer";
import PDFViewer from "../components/PDFViewer";
import ChartComponent from "../components/ChartComponent";
import BenchmarkGraphs from "../components/BenchmarkingGraphs";
import TotalsDisplay from "../components/TotalsDisplay";
import {
  Store as StoreIcon,
  AttachMoney as AttachMoneyIcon,
  People as PeopleIcon,
  TrendingUp as TrendingUpIcon,
  Work as WorkIcon,
  Balance as BalanceIcon,
  Person as PersonIcon,
  Login as LoginIcon,
  Schedule as ScheduleIcon,
  HourglassFull as HourglassFullIcon,
  Forum as ForumIcon,
  Groups as GroupsIcon,
  School as SchoolIcon,
  Event as EventIcon,
} from '@mui/icons-material';

import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';

const data = {
  ytdData: [
    {
      value: "255",
      label: "Businesses Started",
      icon: "store",
    },
    {
      value: "$48.1 Million",
      label: "Loans Obtained",
      icon: "attach_money",
    },
    {
      value: "2,725",
      label: "Clients Served",
      icon: "people",
    },
    {
      value: "$33.8 Million",
      label: "Clients Sales Increase",
      icon: "trending_up",
    },
    {
      value: "879",
      label: "Jobs Created",
      icon: "work",
    },
    {
      value: "$2.49 / $1.00",
      label: "Benefit-to-Cost Ratio",
      icon: "balance",
    },
  ],
  academyData: [
    {
      value: "2,757",
      label: "Total New Users",
      icon: "person",
    },
    {
      value: "1199",
      label: "Users Logged In",
      icon: "login",
    },
    {
      value: "946 Hours",
      label: "Total eCounseling Time",
      icon: "hourglass_full",
    },
    {
      value: "46.8 Minutes",
      label: "Avg eCounseling Time",
      icon: "schedule",
    }
  ],
  topCourses: {
    "Forming Your Business": 24.59,
    "Financing Your Business": 11.49,
    "Marketing Your Business": 4.73,
    "Business Startup": 54.05,
    "Business Growth": 2.03,
    "E-Commerce for Your Business": 0.95,
    "Managing Your Employees": 0.81,
    "Managing Your Customers": 1.35
  },
  sideData: [
    { title: 'SBA', url: 'https://utahsbdc.org/wp-content/uploads/2021/07/powered_by_SBA-1.png' },
    { title: 'GOEO', url: 'https://utahsbdc.org/wp-content/uploads/2021/07/small-UTGOED.png' },
    { title: 'USU', url: 'https://utahsbdc.org/wp-content/uploads/2022/02/usu-extension-22.png' },
  ],
};

const iconSize = 44;

const iconMap = {
  store: <StoreIcon sx={{ fontSize: iconSize }} />,
  attach_money: <AttachMoneyIcon sx={{ fontSize: iconSize }} />,
  people: <PeopleIcon sx={{ fontSize: iconSize }} />,
  trending_up: <TrendingUpIcon sx={{ fontSize: iconSize }} />,
  work: <WorkIcon sx={{ fontSize: iconSize }} />,
  balance: <BalanceIcon sx={{ fontSize: iconSize }} />,
  person: <PersonIcon sx={{ fontSize: iconSize }} />,
  login: <LoginIcon sx={{ fontSize: iconSize }} />,
  schedule: <ScheduleIcon sx={{ fontSize: iconSize }} />,
  hourglass_full: <HourglassFullIcon sx={{ fontSize: iconSize }} />,
  forum: <ForumIcon sx={{ fontSize: iconSize }} />,
  groups: <GroupsIcon sx={{ fontSize: iconSize }} />,
  school: <SchoolIcon sx={{ fontSize: iconSize }} />,
  event: <EventIcon sx={{ fontSize: iconSize }} />,
};

const EntrepreneurAcademy = ({ data, topCourses }) => {
  useEffect(() => {
    gsap.fromTo(
      ".academy-card",
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, duration: 1, stagger: 0.2 }
    );
  }, []);

  const pieData = {
    labels: Object.keys(topCourses),
    datasets: [
      {
        data: Object.values(topCourses),
        backgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#4BC0C0',
          '#9966FF',
          '#FF9F40',
          '#FF6384',
          '#36A2EB',
        ],
        hoverBackgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#4BC0C0',
          '#9966FF',
          '#FF9F40',
          '#FF6384',
          '#36A2EB',
        ]
      }
    ]
  };

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.label || '';
            if (label) {
              label += ': ';
            }
            if (context.raw !== null) {
              label += context.raw + '%';
            }
            return label;
          }
        }
      }
    }
  };

  return (
    <Grid container spacing={4}>
     
      <Box sx={{ width: '100%', textAlign: 'center', mb: 0 }}>
        <img src="https://lwfiles.mycourse.app/631e631a474491b58ad0d9c0-public/56794b587298baf4b7072eef5aba21c5.png" alt="logo" style={{ maxWidth: 500, marginTop: 10 }} />
      </Box>
      <Typography sx={{ color: '#222', textAlign: 'center', width: '100%', mt: 10 }}>Data Since February 5, 2024</Typography>
      {data.map((stat, index) => (
        <Grid item xs={3} key={index}>
          <Card className="academy-card">
            <CardContent>
              {iconMap[stat.icon]}
              <Typography variant="h4">{stat.value}</Typography>
              <Typography>{stat.label}</Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>Top Courses</Typography>
            <Pie data={pieData} options={options} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export const PartnerReportsEA = (props) => {
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('/.netlify/functions/fetchPartnerData');
      const jsonResponse = await response.json();
      console.log(jsonResponse);
    };

    fetchData();
  }, []);

  return (
    <PageWrapper title="EA Report" showSecondaryTitle={true} secondaryTitle={`2024 YTD`}>
      <Typography>Last Updated 8/13/2024</Typography>
      <EntrepreneurAcademy data={data.academyData} topCourses={data.topCourses} />
    </PageWrapper>
  );
};
