import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { CodeSnippet } from "../components/code-snippet";
import { PageLayout } from "../components/page-layout";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import { styled } from '@mui/material/styles';


import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import CountyChart from "../components/charts/CountyChart";

import CircularProgress from '@mui/material/CircularProgress';


import { PageWrapper } from "./PageWrapper";
import HeyzineViewer from "../components/HeyZineViewer";
import PDFViewer from "../components/PDFViewer";
import ChartComponent from "../components/ChartComponent";
import BenchmarkGraphs from "../components/BenchmarkingGraphs";
import TotalsDisplay from "../components/TotalsDisplay";



import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

const data = [
  { source: 'Word of Mouth', percentage: '46%' },
  { source: 'Government Programs', percentage: '13%' },
  { source: 'Online', percentage: '12%' },
  { source: 'SBA', percentage: '10%' },
  { source: 'Other', percentage: '8%' },
  { source: 'Chamber of Commerce', percentage: '3%' },
  { source: 'Lenders', percentage: '3%' },
  { source: 'Higher Ed', percentage: '3%' },
  { source: 'Goldman Sachs', percentage: '2%' },
];

const ReferralSourceTable = () => {
  return (
    <Box sx={{ width: '100%', padding: 2 }}>
      <Typography
        variant="h5"
        component="h2"
        sx={{ backgroundColor: '#002d62', color: '#fff', padding: 3, borderRadius: '4px 4px 0 0' }}
      >
        Referral Source
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>Referral Source</strong></TableCell>
              <TableCell align="right"><strong>%</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.source}</TableCell>
                <TableCell align="right">{row.percentage}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};


export const PartnerReports = (props) => {

    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    

useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('/.netlify/functions/fetchPartnerData');
      const jsonResponse = await response.json();
    //   const kpiData = jsonResponse.results.map(page => ({
    //     kpi: page.properties.KPI.title[0].plain_text,
    //     value: page.properties.Value.rich_text[0].plain_text,
    //     id: page.id
    //   }));
    //   setData(kpiData);

    console.log(jsonResponse);
    };


  
    fetchData();
  }, []);


   
    return (
       <PageWrapper title="Utah SBDC Impact Report" showSecondaryTitle={true} secondaryTitle={`2024 YTD`}>
        <Typography>Last Updated 5/31/2024</Typography>
        <TotalsDisplay/>
<ReferralSourceTable/>

                            













</PageWrapper>
    );
};
