import React from "react";
import { Auth0Features } from "../components/auth0-features";
import { FeatureBox } from "../components/feature-box";
import { HeroBanner } from "../components/hero-banner";
import { PageLayout } from "../components/page-layout";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useAuth0 } from "@auth0/auth0-react";
import AgendaTable from "../components/AgendaTable";
import { PageWrapper } from "./PageWrapper";
import { Typography } from "@mui/material";
import UpcomingEvents from "../components/UpcomingEvents";
import NotionEvents from "../components/dashboard/NotionEvents";
import DirectorsMeeting from "../components/DirectorsMeeting";
import HotelInformation from "../components/HotelInformation";
import MenuBar from "../components/MenuBar";



export const HomePage = () => {
  const { user } = useAuth0();
  const firstName = user.name.split(" ")[0]; // Split the name at the space and take the first part

  


  return (
  
 
      <PageWrapper title={`Welcome ${firstName}!`} showSecondaryTitle={false}>
             {/* <HeroBanner /> */}
      <MenuBar/>
      <Typography sx={{fontSize: 12, mb: 4, px: 1, mt: 3}}>
      Welcome to the SBDC Network Dashboard. This is where you'll find the latest updates on our initiatives and programs. Check in regularly for the most recent information to stay on top of deadlines, changes, and new developments. Everything you need to stay informed is right here.
  </Typography>
      <Grid sx={{ mt: 2 }} container spacing={2} sx={{width: '100%', mt: 2}}>
       
<NotionEvents/>
    {/* <UpcomingEvents events={eventsData}/> */}
    

      

      {/* <Auth0Features /> */}

      </Grid>
      </PageWrapper>

  
  )
};
