import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from 'react';
import axios from 'axios';

import { CodeSnippet } from "../components/code-snippet";
import { PageLayout } from "../components/page-layout";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { CardMedia, Divider } from "@mui/material";
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PieChart from "../components/charts/CountyChart";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';

const EmployeeCard = ({ employee }) => {
    return (
        <Card sx={{ minWidth: 150 }}>
            <CardContent>
                <CardMedia
                    component="img"
                    height="150"
                    image={employee.image}
                    alt={employee.name}
                />

                <Typography sx={{ fontSize: 14, fontWeight: 'bold', textAlign: 'center', textTransform: 'capitalize', mt: 1 }} gutterBottom>
                    {employee.name}
                </Typography>

                <Typography sx={{ fontSize: 10, textAlign: 'center', mt: 0 }} gutterBottom>
                    {employee.position}<br />
                    {employee.center} Center
                </Typography>
                <Divider sx={{ mb: 1 }} />
                <Typography sx={{ fontSize: 10, textAlign: 'center' }} gutterBottom>
                    <LocalPhoneIcon sx={{ mr: .5, pt: .5 }} fontSize="small" />{employee.phone}
                </Typography>
                <Typography sx={{ fontSize: 10, textAlign: 'center', mt: 0 }} gutterBottom>
                    <EmailIcon sx={{ mr: .5, pt: .5 }} fontSize="small" />{employee.email}
                </Typography>






            </CardContent>

        </Card>

    )
}


export const NetworkDirectoryPage = () => {
    const { user } = useAuth0();

    useEffect(() => {

    }, []);

    if (!user) {
        return null;
    }


    const bull = (
        <Box
            component="span"
            sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
        >
            •
        </Box>
    );

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));


    const networkPersonnel = [
        {
            "name": "Joni Anderson",
            "email": "andersonjoni@suu.edu",
            "phone": "435-865-7707",
            "position": "Regional Director",
            "center": "Cedar City",
            "image": "https://utahsbdc.org/wp-content/uploads/2021/06/JoniAnderson.jpg"
        },
        {
            "name": "Shawn Beus",
            "email": "shawnbeus@weber.edu",
            "phone": "801-626-7232",
            "position": "Regional Director",
            "center": "Ogden",
            "image": "https://utahsbdc.org/wp-content/uploads/2021/06/ShawnBeus.jpg"
        },
        {
            "name": "Gaylyn Bradshaw",
            "email": "gaylyn.bradshaw@usu.edu",
            "phone": "435-720-7472",
            "position": "Administrative Assistant",
            "center": "Lead",
            "image": "https://utahsbdc.org/wp-content/uploads/2022/02/Gaylyn-Bradshaw.jpg"
        },
        {
            "name": "Marsha Campbell",
            "email": "marsha.campbell@usu.edu",
            "phone": "435-770-6191",
            "position": "Associate Director",
            "center": "Logan",
            "image": "https://utahsbdc.org/wp-content/uploads/2021/06/MarshaCampbell-2.jpg"
        },
        {
            "name": "Tim Chamberlain",
            "email": "tim.chamberlain@snow.edu",
            "phone": "435-283-7376",
            "position": "Regional Director",
            "center": "Ephraim",
            "image": "https://utahsbdc.org/wp-content/uploads/2021/06/TimChamberlain.jpg"
        },
        {
            "name": "Jess Clifford",
            "email": "jclifford@tooeletech.edu",
            "phone": "435-248-1892",
            "position": "Regional Director",
            "center": "Tooele",
            "image": "https://utahsbdc.org/wp-content/uploads/2021/06/JessClifford.jpg"
        },
        {
            "name": "Cameron Findlay",
            "email": "findlay@dixietech.edu",
            "phone": "435-674-8477",
            "position": "Associate Director",
            "center": "St. George",
            "image": "https://utahsbdc.org/wp-content/uploads/2022/02/cam.jpg"
        },
        {
            "name": "Mike Finnerty",
            "email": "mike.finnerty@usu.edu",
            "phone": "801-633-5595",
            "position": "State Director",
            "center": "Lead",
            "image": "https://utahsbdc.org/wp-content/uploads/2022/02/Mike-Finnerty.jpg"
        },
        {
            "name": "Aaron Griffiths",
            "email": "aaron.griffiths@usu.edu",
            "phone": "435-764-4160",
            "position": "Director, Design & Development",
            "center": "Lead",
            "image": "https://utahsbdc.org/wp-content/uploads/2022/02/Aaron-Griffiths.jpg"
        },
        {
            "name": "Janette Wagner",
            "email": "Janette.Wagner@snow.edu",
            "phone": "435-893-2206",
            "position": "Associate Director",
            "center": "Richfield",
            "image": "https://utahsbdc.org/wp-content/uploads/2024/02/Janette_Profile.jpg"
        },
        {
            "name": "Bryce Hansen",
            "email": "bryce.hansen@slcc.edu",
            "phone": "801-957-5352",
            "position": "Associate Director",
            "center": "Salt Lake City",
            "image": "https://utahsbdc.org/wp-content/uploads/2022/10/Bryce-Hansen.jpg"
        },
        {
            "name": "Robert Head",
            "email": "robert.head@usu.edu",
            "phone": "801-949-5778",
            "position": "Market Research Analyst",
            "center": "Lead",
            "image": "https://utahsbdc.org/wp-content/uploads/2022/02/Rob-Head.jpg"
        },
        {
            "name": "Jim Herrin",
            "email": "jim.herrin@slcc.edu",
            "phone": "801-957-5441",
            "position": "Regional Director",
            "center": "Salt Lake City",
            "image": "https://utahsbdc.org/wp-content/uploads/2022/10/Jim-Herrin.jpg"
        },
        {
            "name": "Mark Holmes",
            "email": "mark.holmes@usu.edu",
            "phone": "435-722-1779",
            "position": "Regional Director",
            "center": "Vernal",
            "image": "https://utahsbdc.org/wp-content/uploads/2021/06/MarkHolmes.jpg"
        },
        {
            "name": "Roger Koyle",
            "email": "roger.koyle@usu.edu",
            "phone": "435-613-5460",
            "position": "Regional Director",
            "center": "Price",
            "image": "https://utahsbdc.org/wp-content/uploads/2023/04/RogerKoyle.jpg"
        },
        {
            "name": "Jeff Mather",
            "email": "jmather@dixietech.edu",
            "phone": "435-674-8477",
            "position": "Regional Director",
            "center": "St. George",
            "image": "https://utahsbdc.org/wp-content/uploads/2021/06/JeffMather.jpg"
        },
        {
            "name": "Camille Pendleton",
            "email": "camillep@uvu.edu",
            "phone": "801-863-8230",
            "position": "Regional Director",
            "center": "Orem",
            "image": "https://utahsbdc.org/wp-content/uploads/2021/06/CamillePendleton.jpg"
        },
        {
            "name": "Wes Willis",
            "email": "andrew.willis@davistech.edu",
            "phone": "801-593-2299",
            "position": "Regional Director",
            "center": "Kaysville",
            "image": "https://utahsbdc.org/wp-content/uploads/2022/08/Andrew-e1660658315104-600x600.jpg"
        },
        {
            "name": "Brady Wood",
            "email": "brady.wood@usu.edu",
            "phone": "435-225-5314",
            "position": "Program Coordinator",
            "center": "Lead",
            "image": "https://utahsbdc.org/wp-content/uploads/2022/02/Brady-Wood.jpg"
        },
        {
            "name": "Jason Yerka",
            "email": "jason.yerka@usu.edu",
            "phone": "435-760-9364",
            "position": "Associate State Director",
            "center": "Lead",
            "image": "https://utahsbdc.org/wp-content/uploads/2022/02/Jason-Yerka.jpg"
        },
        {
            "name": "Mike Young",
            "email": "smike.young@usu.edu",
            "phone": "435-797-2277",
            "position": "Regional Director",
            "center": "Logan",
            "image": "https://utahsbdc.org/wp-content/uploads/2021/06/MikeYoung.jpg"
        }


    ]


    return (
        <PageLayout>

            <div className="content-layout">

                <Typography sx={{ textTransform: 'capitalize' }}>
                    <h1 id="page-title" >
                        Network Directory
                    </h1>
                </Typography>



                <div className="content__body">
                    <Grid container spacing={2} sx={{ mt: 1.5 }}>


                        {networkPersonnel.map((employee) => (
                            <Grid lg={2}>
                                <EmployeeCard employee={employee} />
                            </Grid>
                        ))}








                        <Grid lg={4}>

                        </Grid>
                        <Grid lg={4}>

                        </Grid>

                    </Grid>

                </div>
            </div>
        </PageLayout>
    );
};
