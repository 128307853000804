import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Box, Typography, Card, CardContent } from '@mui/material';

const PartnerPie = ({ data, title, options }) => {
  const chartData = {
    labels: Object.keys(data),
    datasets: [
      {
        data: Object.values(data),
        backgroundColor : [
            'rgba(233, 30, 99, 1)',
            'rgba(33, 150, 243, 1)',
            'rgba(254, 234, 167, 1)',
            'rgba(255, 87, 34, 1)',
            'rgba(142, 36, 170, 1)',
            'rgba(0, 188, 212, 1)',
            'rgba(244, 67, 54, 1)',
            'rgba(0, 150, 136, 1)',
            'rgba(233, 196, 106, 1)',
            'rgba(103, 58, 183, 1)',
            'rgba(3, 169, 244, 1)',
            'rgba(245, 124, 0, 1)',
            'rgba(139, 195, 74, 1)',
            'rgba(162, 95, 173, 1)',
            'rgba(205, 220, 57, 1)',
            'rgba(97, 200, 142, 1)',
            'rgba(96, 125, 139, 1)',
            'rgba(239, 83, 80, 1)',
            'rgba(255, 193, 7, 1)',
            'rgba(139, 195, 74, 1)',
            '#FFFFFF'
          ],
      },
    ],
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  const cardStyles = {
    userSelect: 'none',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
  };

  return (
    <Card onContextMenu={handleContextMenu} style={cardStyles} sx={{  m: 2, mb: 4}}>
      <CardContent>
        <Box sx={{ p: 2}}>
          <Typography variant="h5" sx={{fontSize: 20, textAlign: 'center', mb: 4}} component="div" gutterBottom>
            {title}
          </Typography>
          <Pie data={chartData} options={options} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default PartnerPie;
