import React from "react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Card, Divider, Typography } from "@mui/material";
import { PageLayout } from "../components/page-layout";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from "react-router-dom";




const strategicTeamMembers = [
    {
        "title": "Sustainable Funding",
      "color": "#652D90c7",
        "id": 74,
        "description": "The demands for services and the costs to operate the Utah SBDC network continue to increase. Local, state and federal resources are tightening. The network must ensure sustainable funding is provided to maintain and expand services provided to the program clients.",
  
        "members": [{
            "name": "Mike Finnerty"
        },
        {
            "name": "Jim Herrin"
        },
        {
            "name": "Bryce Hansen"
        },
        {
            "name": "Jeff Mather"
        }]
    },
    {
        "title": "Human Capital",
        "color": "#D6DE23c7",
        "description": "The Utah SBDC must continue to attract, retain, and educate high quality regional directors, business advisors and trainers to support Utah entrepreneurs and small businesses. The Utah SBDC should ensure that all centers provide expertise, guidance, access to resources and relationships necessary to support client’s development and growth.",
        "id": 77,
        "members": [{
            "name": "Shawn Beus"
        },
        {
            "name": "Bryce Hansen"
        },
        {
            "name": "Camille Pendleton"
        },
        {
            "name": "Joni Anderson"
        },
        {
            "name": "Jason Yerka"
        },
        {
            "name": "Roger Koyle"
        },
        {
            "name": "Andrew Willis"
        }
        ]
    },
    {
        "title": "Market Segmentation",
        "color": "#37B34Ac7",
        "id": 78,
        "description": "The Utah SBDC should stay abreast of changes (e.g. demographic, economic and technological) that impact the existing marketplace and create new markets. Based on identified trends, the network should tailor its services, resources and programs in formats appropriate to the target markets identified.",
        
        "members": [{
            "name": "Janette Wagner"
        },
        {
            "name": "Andrew Willis"
        },
        {
            "name": "Matthias Miller"
        },
        {
            "name": "Shawn Beus"
        },
        {
            "name": "Brady Wood"
        },
        {
            "name": "Robert Head"
        }
        ]
    },
    {
        "title": "Marketing and Promotion",
        "color": "#006838c7",
        "id": 75,
        "description": "The Utah SBDC understands the breadth and fragmentation of resources available to small business owners and prospective entrepreneurs. The network must establish itself as the leading source of assistance for small business and entrepreneurs and must stand out in a dynamic and crowded marketplace with a strong image, unmistakable message and the ability to provide a clearly defined array of services that can be tailored to meet the needs of a divergent client group.",
        "members": [{
            "name": "Mark Holmes"
        },
        {
            "name": "Brady Wood"
        },
        {
            "name": "Peter Callister"
        },
        {
            "name": "Andrew Willis"
        },
        {
            "name": "Robert Head"
        },
        {
            "name": "Cam Findlay"
        }
        ]
    },
    {
        "title": "Technology Utilization",
        "color": "#00A69Cc7",
        "id": 79,
        "description": "The Utah SBDC recognizes the positive impact of innovation and technology development on the economic vitality of the state. The network also recognizes the need to be innovative and to effectively deploy available technology to improve efficiency and effectiveness in targeting and delivering services to clients and help them improve their business operations.",
        "members": [{
            "name": "Aaron Griffiths"
        },
        {
            "name": "Mike Young"
        },

        {
            "name": "Jess Clifford"
        },
        {
            "name": "Matthias Miller"
        }

        ]
    },
    {
        "title": "Leadership & Collaboration",
        "color": "#1B75BBc7",
        "id": 72,
        "description": "The Utah SBDC recognizes the importance of maintaining balance among stakeholder priorities. The network should play a leadership role to actively enable collaboration and close communication with network partners, external partners and stakeholders to develop consistent delivery of relevant content and the use of all available resources to meet identified strategic objectives.",
        "members": [{
            "name": "Jess Clifford"
        },
        {
            "name": "Tim Chamberlain"
        },
        {
            "name": "Mark Holmes"
        },
        {
            "name": "Jeff Mather"
        },
        {
            "name": "Mike Finnerty"
        },
        {
            "name": "Janette Wagner"
        },
        {
            "name": "Cam Findlay"
        }

        ]
    },
    {
        "title": "Program Reporting / Systems Compliance",
        "color": "#BE1E2Dc7",
        "id": 73,
        "description": "The Utah SBDC recognizes the need to integrate new initiatives from the OSBDC and ASBDC Accreditation into the operations of the network. It also recognizes the importance of accurate reporting supported by sustainable systems.",
        "members": [{
            "name": "Joni Anderson"
        },
        {
            "name": "Mark Holmes"
        },
        {
            "name": "Jason Yerka"
        },
        {
            "name": "Camille Pendleton"
        }

        ]
    },
    {
        "title": "Capacity for Globalization",
        "color": "#F05A28c7",
        "id": 76,
        "description": "The Utah SBDC recognizes the importance for Utah firms to be competitive in a global economy. The network should play a leadership role in educating clients regarding the impact of globalization in all aspects of their business strategy, operations and growth.",
        "members": [{
            "name": "Jim Herrin"
        },
        {
            "name": "Tim Chamberlain"
        },
        {
            "name": "Jason Yerka"
        }


        ]
    }
]





const TeamContainer = ({ title, members, color }) => (
    <Card sx={{ mt: 1 }}>
        <Typography sx={{ fontSize: 14, textAlign: 'center', pt: 1, backgroundColor: color, color: "#fff", pb: 1 }} color="text.primary" gutterBottom>
            {title}
        </Typography>
        {/* <Divider /> */}
        <Typography sx={{ fontSize: 14, textAlign: 'center', pt: 1 }} color="text.secondary" gutterBottom>



            <TableContainer>
                <Table aria-label="simple table">
                    {/* <TableHead>
                        <TableRow>
                            <TableCell>Members</TableCell>

                        </TableRow>
                    </TableHead> */}
                    <TableBody>
                        {members.map((member) => (
                            <TableRow
                                key={member.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell sx={{ textAlign: 'center', fontSize: '12px' }} component="th" scope="row">
                                    {member.name}
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </Typography>
    </Card>
)

export const StrategicTeamsPage = () => (
    <PageLayout>
      <div className="content-layout">
        <Typography sx={{ textTransform: 'capitalize', mb: 2 }}>
          <h1 id="page-title">Strategic Teams</h1>
        </Typography>
  
        {/* Add the Button component here */}
        <Button
          variant="contained"
          color="primary"
          sx={{ mb: 2 }}
          href="https://drive.google.com/file/d/1dMXglQJLsbAzbSEcZSzDXmDFHHdKxNCx/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          View Strategic Plan
        </Button>
  
        <div className="content__body">
          <Grid container spacing={2}>
            {strategicTeamMembers.map((team, index) => (
              <Grid lg={3} key={index}>
                <Link to={`/strategic-teams/detail/${team.id}`}>
                  <TeamContainer title={team.title} members={team.members} color={team.color} />
                </Link>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </PageLayout>
  );
