
import React from "react";
import HotelInformation from './HotelInformation';
import { Button, Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { Link } from "react-router-dom"; // import Link
export const HeroBanner = () => {
  const logo = "https://cdn.auth0.com/blog/developer-hub/react-logo.svg";

  return (
    <div className="hero-banner hero-banner--blue-aqua">
      <div className="hero-banner__logo">
        <img className="hero-banner__image" src={'https://utahsbdc.org/wp-content/uploads/2020/11/Logo-COLOR_1.png'} alt="React logo" />
      </div>
      <Typography sx={{ textAlign: 'center', mb: 6}}>


        <h1 className="hero-banner__headline">Director's Meeting</h1>
     
        <Typography sx={{fontSize: 20, mb: 3, color: '#fff'}}>
        October 24, 2023
        </Typography>
        <Button variant="outlined" color="white" component={Link} to="/directors-meeting">View Agenda</Button>
       
      </Typography>
      <Card sx={{padding: 2}}>
      <HotelInformation/>
          </Card>

      {/* <a
        id="code-sample-link"
        target="_blank"
        rel="noopener noreferrer"
        href="https://annualreport.utahsbdc.org"
        className="button button--secondary"
      >
        See 2022 Success Stories →
      </a> */}

    </div>
  );
};
