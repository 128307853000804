import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PieChartIcon from '@mui/icons-material/PieChart';
import StorageIcon from '@mui/icons-material/Storage';
import HomeIcon from '@mui/icons-material/Home';
import SchoolIcon from '@mui/icons-material/School';
import CampaignIcon from '@mui/icons-material/Campaign';
import PollIcon from '@mui/icons-material/Poll';

import { Link } from "react-router-dom";


export const mainListItems = (
  <React.Fragment>

    <Link to={'/'}>
      <ListItemButton>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText sx={{ color: '#000' }} primary="Home" />
      </ListItemButton>
    </Link>


    <Link to={'network-directory'}>
      <ListItemButton>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText sx={{ color: '#000' }} primary="Network Directory" />
      </ListItemButton>
    </Link>


    <Link to={'strategic-teams'}>
      <ListItemButton>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText sx={{ color: '#000' }} primary="Strategic Teams" />
      </ListItemButton>
    </Link>
 
  </React.Fragment >
);

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Tools
    </ListSubheader>


    {/* <Link to={'course-review'}>
      <ListItemButton>
        <ListItemIcon>
          <SchoolIcon />
        </ListItemIcon>
        <ListItemText sx={{ color: '#000' }} primary="Course Review" />
      </ListItemButton>
    </Link> */}




    <Link to={'market-segmentation'}>
      <ListItemButton>
        <ListItemIcon>
          <PieChartIcon />
        </ListItemIcon>
        <ListItemText sx={{ color: '#000' }} primary="Market Segmentation" />
      </ListItemButton>
    </Link>


    <Link to={'marketing-plan'}>
      <ListItemButton>
        <ListItemIcon>
          <CampaignIcon />
        </ListItemIcon>
        <ListItemText sx={{ color: '#000' }} primary="Marketing Plan" />
      </ListItemButton>
    </Link>
{/* 
    <ListItemButton>
      <ListItemIcon>
        <StorageIcon />
      </ListItemIcon>
      <ListItemText primary="Resource Database" />
    </ListItemButton> */}
  <Link to={'brand-assets'}>
    <ListItemButton>
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText sx={{ color: '#000' }} primary="Brand Assets" />
    </ListItemButton>
    </Link>


    <Link to={'reports'}>
      <ListItemButton>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText sx={{ color: '#000' }} primary="EA Reports" />
      </ListItemButton>
    </Link>


  </React.Fragment>
);
