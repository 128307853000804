import React from "react";
import { NavBar } from "./navigation/desktop/nav-bar";
import { MobileNavBar } from "./navigation/mobile/mobile-nav-bar";
import { PageFooter } from "./page-footer";
import { Box } from "@mui/material";

export const PageLayout = ({ children }) => {
  return (
    <div className="page-layout">
     <Box    sx={{
        width: '100%', // default width for mobile
        '@media (min-width: 600px)': {
          // width for screens larger than 600px (e.g., tablets, desktops)
          width: '80%',
        },
        mx: 'auto', // centers the box
      }}>
      {/* <NavBar />
      <MobileNavBar /> */}
      <div className="page-layout__content">{children}</div>
      {/* <PageFooter /> */}
      </Box>
    </div>
  );
};
