import React from 'react';
import { Grid, Box } from '@mui/material';
import PartnerCard from './PartnerCard';

const PartnerImpact = ({ totals }) => {
  const data = [
    { title: 'Business Starts', value: totals.BusinessStarts, image: 'https://utahsbdc.org/wp-content/uploads/2023/10/businessStarts.jpg' },
    { title: 'Capital', value: totals.Capital, image: 'https://utahsbdc.org/wp-content/uploads/2023/10/LoansObtained.jpg' },
    { title: 'Clients', value: totals.Clients, image: 'https://utahsbdc.org/wp-content/uploads/2023/10/clientsServed.jpg' },
    { title: 'Sales', value: totals.Sales, image: 'https://utahsbdc.org/wp-content/uploads/2023/10/ClientSales.jpg' },
    { title: 'Jobs', value: totals.Jobs, image: 'https://utahsbdc.org/wp-content/uploads/2023/10/JobsCreated.jpg' },
    { title: 'Hours', value: totals.Hours, image: 'https://utahsbdc.org/wp-content/uploads/2024/06/Time.jpg' },
  ];

  return (
    <Box sx={{ p: 1, m: 1 }}>
      <Grid container spacing={2} justifyContent="flex-start">
        {data.map((item, index) => (
          <Grid item key={index} xs={12} sm={6} md={4} lg={4}>
            <PartnerCard title={item.title} value={item.value} image={item.image} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PartnerImpact;
