import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

const MenuBar = () => {
  return (
    <AppBar position="static" sx={{mt: 2}} >
      <Toolbar sx={{background: '#002d62'}}>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
         Quick Links
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}> {/* Adjust the gap value as needed */}
       
        <Button color="inherit" variant="outlined" target='_blank' href="https://clients.utahsbdc.org/ic3/cic/">Center IC</Button>
        <Button color="inherit" variant="outlined" target='_blank' href="https://online.utahsbdc.org">Entrepreneur Academy</Button>
        <Button color="inherit" variant="outlined" target='_blank' href="https://utahsbdc.org">Utah SBDC Website</Button>
        </Box>
        
        {/* <Button color="inherit" href="#portfolio">Portfolio</Button>
        <Button color="inherit" href="#contact">Contact</Button> */}
      </Toolbar>
    </AppBar>
  );
};

export default MenuBar;