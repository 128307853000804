import React from 'react';
import { Button, Card, CardContent, CardMedia, Divider, Grid, Typography } from '@mui/material';
import { PageWrapper } from '../pages/PageWrapper';

const BrandAssets = () => {
  const logos = [
    {
      title: 'Utah SBDC Logo',
      imageUrl: '/images/branding/Utah-color-with-band-thumb.png',
      linkUrl: '/images/branding/Utah-color-with-band-thumb.png',
      fileType: 'png',
    },
    {
      title: 'Utah SBDC Logo (Vector)',
      imageUrl: '/images/branding/Utah-color-with-band-thumb.png',
      linkUrl: '/images/branding/Utah-color-with-band.eps',
      fileType: 'eps',
    }, {
        title: 'Powered By SBA Logo (JPEG)',
        imageUrl: '/images/branding/SBA-PoweredBy.jpg',
        linkUrl: '/images/branding/SBA-PoweredBy.jpg',
        fileType: 'jpg',
      },
      , {
        title: 'Powered By SBA Logo (PNG)',
        imageUrl: '/images/branding/SBA-PoweredBy.png',
        linkUrl: '/images/branding/SBA-PoweredBy.png',
        fileType: 'png',
      },

      , {
        title: 'Powered By SBA Logo (EPS - Vector)',
        imageUrl: '/images/branding/SBA-PoweredBy.jpg',
        linkUrl: '/images/branding/SBA-PoweredBy-2color.eps',
        fileType: 'eps',
      },


      {
        title: 'Go Utah Logo',
        imageUrl: '/images/branding/Go_Utah_BLACK_Standard.png',
        linkUrl: '/images/branding/Go_Utah_BLACK_Standard.png',
        fileType: 'png',
      },

      {
        title: 'Go Utah White Logo',
        imageUrl: '/images/branding/Go_Utah_white_Standard.png',
        linkUrl: '/images/branding/Go_Utah_white_Standard.png',
        fileType: 'png',
        version: 'light'
      },


     
      
      //https://utah-sbdc-website.s3.us-west-2.amazonaws.com/sba-logos.zip
    // Add more logos here
  ];

  const flyers = [
    {
      title: 'Utah SBDC Overview Flyer',
      imageUrl: 'https://utahsbdc.org/wp-content/uploads/2024/08/sbdc_flyer.jpg',
      linkUrl: 'hhttps://utah-sbdc-website.s3.us-west-2.amazonaws.com/UtahSBDC_Flyer_2024.pdf',
      fileType: 'pdf',
    }
    // Add more flyers here
  ];

  const handleDownload = (imageUrl, linkUrl, fileName) => {
    const link = document.createElement('a');
    link.href = linkUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <PageWrapper title="Brand Assets">
      <Grid container spacing={3}>
        {logos.map((logo, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <Card style={{ maxWidth: 345, backgroundColor: logo.version === 'light' ? '#606974' : 'white', color: logo.version === 'light' ? 'white' : 'black' }}>
              <CardMedia sx={{ height: 140, backgroundSize: 'contain', margin: '20px' }} image={logo.imageUrl} title={logo.title} />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div" style={{ color: logo.version === 'light' ? 'white' : 'black' }}>
                  {logo.title}
                </Typography>
                <Divider sx={{ mt: 1, mb: 2 }}></Divider>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleDownload(logo.imageUrl, logo.linkUrl, `${logo.title}.${logo.fileType}`)}
                >
                  Download
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      
      <Divider sx={{ mt: 4, mb: 4 }} />c

      <Typography variant="h4" component="h2" gutterBottom>
        Flyers
      </Typography>
      
      <Grid container spacing={3}>
        {flyers.map((flyer, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <Card style={{ maxWidth: 345 }}>
              <CardMedia sx={{ height: 140, backgroundSize: 'contain', margin: '20px' }} image={flyer.imageUrl} title={flyer.title} />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {flyer.title}
                </Typography>
                <Divider sx={{ mt: 1, mb: 2 }}></Divider>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleDownload(flyer.imageUrl, flyer.linkUrl, `${flyer.title}.${flyer.fileType}`)}
                >
                  Download
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </PageWrapper>
  );
};

export default BrandAssets;
