import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PartnerImpact from './PartnerImpact'; // Ensure the path is correct
import PartnerPie from './PartnerPie'; // Ensure the path is correct
import { Box, MenuItem, Select, Typography, FormControl, InputLabel } from '@mui/material';

const fetchData = async () => {
  const response = await axios.get('/.netlify/functions/fetchPartnerData'); // Adjust the URL as necessary
  return response.data;
};

const TotalsDisplay = () => {
  const [data, setData] = useState(null);
  const [totals, setTotals] = useState(null);
  const [chartData, setChartData] = useState({ womenMinorityRural: {}, sectors: {} });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCenter, setSelectedCenter] = useState('Total');

  useEffect(() => {
    const getData = async () => {
      try {
        const data = await fetchData();
        setData(data);
        const calculatedTotals = calculateTotals(data);
        setTotals(calculatedTotals);
        setChartData(calculateChartData(data));
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  const handleCenterChange = (event) => {
    setSelectedCenter(event.target.value);
    if (event.target.value === 'Total') {
      setTotals(calculateTotals(data));
      setChartData(calculateChartData(data));
    } else {
      const centerData = getCenterData(data, event.target.value);
      setTotals(centerData.totals);
      setChartData(centerData.chartData);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const centers = data.results
    .map((page) => page.properties.Center.title[0].plain_text)
    .sort();

  const naicsOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="body" component="div" sx={{mb: 2, fontSize: 12}} gutterBottom>
        Select Center
      </Typography>
      <FormControl  fullWidth variant="outlined" sx={{ mb: 2}}>
        <InputLabel>Center</InputLabel>
        <Select value={selectedCenter} onChange={handleCenterChange} label="Center" fullWidth>
          <MenuItem value="Total">All Centers</MenuItem>
          {centers.map((center, index) => (
            <MenuItem key={index} value={center}>
              {center}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <PartnerImpact totals={totals} />
      <PartnerPie data={chartData.womenMinorityRural} title="SEDI" />
      <PartnerPie data={chartData.sectors} title="NAICS Sectors" options={naicsOptions} />
    </Box>
  );
};

const calculateTotals = (data) => {
  const totals = {
    Clients: 0,
    Hours: 0,
    BusinessStarts: 0,
    Jobs: 0,
    Capital: 0,
    Sales: 0,
  };

  data.results.forEach((page) => {
    const properties = page.properties;
    totals.Clients += properties.Clients?.number || 0;
    totals.Hours += properties.Hours?.number || 0;
    totals.BusinessStarts += properties['Business Starts']?.number || 0;
    totals.Jobs += properties.Jobs?.number || 0;
    totals.Capital += properties.Capital?.number || 0;
    totals.Sales += properties.Sales?.number || 0;
  });

  return totals;
};

const calculateChartData = (data) => {
  const womenMinorityRural = {
    Women: 0,
    Minority: 0,
    Rural: 0,
  };

  const sectors = {
    Agriculture: 0,
    Mining: 0,
    Utilities: 0,
    Construction: 0,
    Manufacturing: 0,
    Wholesale: 0,
    Retail: 0,
    Transportation: 0,
    Information: 0,
    'Finance & Insurance': 0,
    'Real Estate & Renting': 0,
    'Professional, Scientific & Tech Services': 0,
    'Management Of Companies & Enterprises': 0,
    'Administrative Support': 0,
    'Educational Services': 0,
    'Health Care': 0,
    'Arts & Recreation': 0,
    'Accommodation & Food Services': 0,
    'Other Services': 0,
    'Public Administration': 0,
  };

  data.results.forEach((page) => {
    const properties = page.properties;
    womenMinorityRural.Women += properties.Women?.number || 0;
    womenMinorityRural.Minority += properties.Minority?.number || 0;
    womenMinorityRural.Rural += properties.Rural?.number || 0;

    Object.keys(sectors).forEach((key) => {
      sectors[key] += properties[key]?.number || 0;
    });
  });

  return { womenMinorityRural, sectors };
};

const getCenterData = (data, center) => {
  const centerData = data.results.find(
    (page) => page.properties.Center.title[0].plain_text === center
  );

  const totals = {
    Clients: centerData.properties.Clients?.number || 0,
    Hours: centerData.properties.Hours?.number || 0,
    BusinessStarts: centerData.properties['Business Starts']?.number || 0,
    Jobs: centerData.properties.Jobs?.number || 0,
    Capital: centerData.properties.Capital?.number || 0,
    Sales: centerData.properties.Sales?.number || 0,
  };

  const womenMinorityRural = {
    Women: centerData.properties.Women?.number || 0,
    Minority: centerData.properties.Minority?.number || 0,
    Rural: centerData.properties.Rural?.number || 0,
  };

  const sectors = {
    Agriculture: centerData.properties.Agriculture?.number || 0,
    Mining: centerData.properties.Mining?.number || 0,
    Utilities: centerData.properties.Utilities?.number || 0,
    Construction: centerData.properties.Construction?.number || 0,
    Manufacturing: centerData.properties.Manufacturing?.number || 0,
    Wholesale: centerData.properties.Wholesale?.number || 0,
    Retail: centerData.properties.Retail?.number || 0,
    Transportation: centerData.properties.Transportation?.number || 0,
    Information: centerData.properties.Information?.number || 0,
    'Finance & Insurance': centerData.properties['Financing & Insurance']?.number || 0,
    'Real Estate & Renting': centerData.properties['Real Estating & Renting']?.number || 0,
    'Professional, Scientific & Tech Services': centerData.properties['Professional, Scientific & Tech Services']?.number || 0,
    'Management Of Companies & Enterprises': centerData.properties['Management Of Companies & Enterprises']?.number || 0,
    'Administrative Support': centerData.properties['Administrative Support']?.number || 0,
    'Educational Services': centerData.properties['Educational Services']?.number || 0,
    'Health Care': centerData.properties['Health Care']?.number || 0,
    'Arts & Recreation': centerData.properties['Arts & Recreation']?.number || 0,
    'Accommodation & Food Services': centerData.properties['Accommodation & Food Services']?.number || 0,
    'Other Services': centerData.properties['Other Services']?.number || 0,
    'Public Administration': centerData.properties['Public Administration']?.number || 0,
  };

  return { totals, chartData: { womenMinorityRural, sectors } };
};

export default TotalsDisplay;
