import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { CodeSnippet } from "../components/code-snippet";
import { PageLayout } from "../components/page-layout";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { CardMedia, TextField } from "@mui/material";
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PieChart from "../components/charts/CountyChart";

import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ReceiptForm from "../components/ReceiptForm";
import Upload from "../components/dashboard/Upload";
import { ReceiptHistoryPage } from "./receipt-history-page";
const EmployeeCard = ({ employee }) => {
    return (
        <Card sx={{ minWidth: 275 }}>
            <CardContent>
                <CardMedia
                    component="img"
                    height="300"
                    image={employee.image}
                    alt={employee.name}
                />

                <Typography sx={{ fontSize: 14, textAlign: 'center', textTransform: 'capitalize', mt: 1 }} gutterBottom>
                    {employee.name}
                </Typography>
                <Typography sx={{ fontSize: 12, textAlign: 'center' }} gutterBottom>
                    {employee.phone}
                </Typography>
                <Typography sx={{ fontSize: 12, textAlign: 'center' }} gutterBottom>
                    {employee.email}
                </Typography>





            </CardContent>

        </Card>

    )
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}


export const ReceiptSubmissionPage = () => {
    const { user } = useAuth0();
    const [uploads, setUploads] = useState(0);

    const names = [
        'Aaron Griffiths',
        'Brady Wood',
        'Gaylyn Bradshaw',
        'Jason Yerka',
        'Mike Finnerty'
    ]




    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    useEffect(() => {

    }, []);

    if (!user) {
        return null;
    }


    const bull = (
        <Box
            component="span"
            sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
        >
            •
        </Box>
    );

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));





    return (
        <PageLayout>

            <div className="content-layout">

                <Typography sx={{ textTransform: 'capitalize' }}>
                    <h1 id="page-title" >
                        Receipt Submission Tool
                    </h1>
                </Typography>



                <div className="content__body">
                    <Grid container spacing={2} sx={{ mt: 1.5 }}>








                        <Grid lg={4}>

                            <Card sx={{ textAlign: "center" }}>
                                <CardContent>
                                    <Upload user={user} setUploads={setUploads} />
                                </CardContent>
                            </Card>

                        </Grid>
                        <Grid lg={8}>
                            <ReceiptHistoryPage uploads={uploads} />
                        </Grid>

                    </Grid>

                </div>
            </div>


        </PageLayout>
    );
};
