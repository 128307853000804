import React, { useState, useEffect } from 'react';
import { Typography, Card, CardContent, Button, Grid, CardMedia, Divider } from '@mui/material';
import { PageLoader } from '../page-loader';
import { Link } from 'react-router-dom'; // Import Link at the top of your file


const useNotionTable = () => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const databaseId = "a3dd17fe9ffa41df85422a4b9480980f";
        const response = await fetch(`/.netlify/functions/fetchNotionData?databaseId=${databaseId}`);
        const jsonResponse = await response.json();
        setData(jsonResponse.databaseData); // Ensure you're setting the correct part of the jsonResponse
        console.log(jsonResponse, 'json')
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { data, isLoading, error };
  
}

const getImageUrl = (imageProperty) => {
  // This function checks the type of the image and returns the URL accordingly
  if (imageProperty.files[0].type === 'external') {
    return imageProperty.files[0].external.url;
  } else if (imageProperty.files[0].type === 'file') {
    return imageProperty.files[0].file.url;
  }
  return null;
};



const NotionEvents = () => {
  const { data, isLoading, error } = useNotionTable();

  if (isLoading) return <PageLoader />;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <Grid container spacing={2}>
      {data && data.results.length > 0 ? (
        data.results.map((event) => (
          <Grid item xs={12} key={event.id}>
            <Card sx={{ display: 'flex', mb: 2, width: '100%' }}> {/* Ensure card takes full width */}
  {event.properties.Image && event.properties.Image.files.length > 0 && (
    <CardMedia
      component="img"
      sx={{ width: 200, height: 200, flexShrink: 0 }} // Prevent the image from shrinking
      image={getImageUrl(event.properties.Image)}
      alt="Event Image"
    />
  )}
  <CardContent sx={{ flexGrow: 1, overflow: 'hidden' }}> {/* Allow content to grow and hide overflow */}
    <Typography sx={{ fontSize: 18 }} variant="h5" component="div">
      {event.properties.Subject.title[0].plain_text}
    </Typography>

  {event.properties["Event Date"] && event.properties["Event Date"].rich_text.length > 0 && (
    <Typography sx={{ mb: 1.5 }} color="text.secondary">
      {event.properties["Event Date"].rich_text[0].plain_text}
    </Typography>
  )}

<Divider sx={{mt: 1}}></Divider>
  <Typography sx={{ mt: 1.5, mb: 1.5, fontSize: 12, whiteSpace: 'normal' }}> {/* Ensures text wraps */}
    {event.properties.Description.rich_text[0].plain_text}
  </Typography>

{event.properties["Link 1"] && event.properties["Link 1"].rich_text.length > 0 && event.properties["Link 1 Caption"] && event.properties["Link 1 Caption"].rich_text.length > 0 && (
  <Button
    variant="contained"
    color="primary"
    component={Link} // Use Link as the component
    to={event.properties["Link 1"].rich_text[0].plain_text} // Use 'to' prop for navigation
    sx={{ margin: 1 }}
  >
    {event.properties["Link 1 Caption"].rich_text[0].plain_text}
  </Button>
)}
{event.properties["Link 2"] && event.properties["Link 2"].rich_text.length > 0 && event.properties["Link 2 Caption"] && event.properties["Link 2 Caption"].rich_text.length > 0 && (
  <Button
    variant="contained"
    color="primary"
    component={Link} // Use Link as the component
    to={event.properties["Link 2"].rich_text[0].plain_text} // Use 'to' prop for navigation
    sx={{ margin: 1 }}
  >
    {event.properties["Link 2 Caption"].rich_text[0].plain_text}
  </Button>
)}


              </CardContent>
            </Card>
          </Grid>
        ))
      ) : (
        <Typography variant="body1">No events found.</Typography>
      )}
    </Grid>
  );
};

export default NotionEvents;