import React from "react";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

export const MarketSegmentationTable = ({ data }) => {



    const sectorList = Object.values(data).map(({ sectorId, sectorName, count }) => ({
        'id': sectorId,
        sectorName,
        count,
    }));




    const columns = [
        { field: 'id', headerName: 'ID', width: '5' },
        { field: 'sectorName', headerName: 'NAICS Sector', width: 300 },
        { field: 'count', headerName: 'Count', type: 'number' },

    ];

    const rows = [
        { id: 11, sector: 'Agriculture', count: 15 },

    ];


    return (
        <div style={{ height: 465, width: '100%' }}>
            <DataGrid
                rows={sectorList}
                columns={columns}
                pageSize={25}
                rowsPerPageOptions={[24]}
                components={{
                    Toolbar: GridToolbar
                }} />


        </div>
    );
};
