import React, { useEffect, useState } from 'react';

import axios from 'axios';

import Button from '@mui/material/Button';
import { useLocation, useParams } from "react-router-dom";
import { PageLayout } from "../components/page-layout";
import { Card, CardContent, CardActions, Typography, Table, TableBody, TableCell, Divider } from "@mui/material";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CircularProgress } from '@mui/material';

const strategicTeamMembers = [
    {
        "title": "Sustainable Funding",
      "color": "#652D90c7",
        "id": 74,
        "description": "The demands for services and the costs to operate the Utah SBDC network continue to increase. Local, state and federal resources are tightening. The network must ensure sustainable funding is provided to maintain and expand services provided to the program clients.",
  
        "members": [{
            "name": "Mike Finnerty"
        },
        {
            "name": "Jim Herrin"
        },
        {
            "name": "Bryce Hansen"
        },
        {
            "name": "Jeff Mather"
        }]
    },
    {
        "title": "Human Capital",
        "color": "#D6DE23c7",
        "description": "The Utah SBDC must continue to attract, retain, and educate high quality regional directors, business advisors and trainers to support Utah entrepreneurs and small businesses. The Utah SBDC should ensure that all centers provide expertise, guidance, access to resources and relationships necessary to support client’s development and growth.",
        "id": 77,
        "members": [{
            "name": "Shawn Beus"
        },
        {
            "name": "Bryce Hansen"
        },
        {
            "name": "Camille Pendleton"
        },
        {
            "name": "Joni Anderson"
        },
        {
            "name": "Jason Yerka"
        },
        {
            "name": "Roger Koyle"
        },
        {
            "name": "Andrew Willis"
        }
        ]
    },
    {
        "title": "Market Segmentation",
        "color": "#37B34Ac7",
        "id": 78,
        "description": "The Utah SBDC should stay abreast of changes (e.g. demographic, economic and technological) that impact the existing marketplace and create new markets. Based on identified trends, the network should tailor its services, resources and programs in formats appropriate to the target markets identified.",
        
        "members": [{
            "name": "Janette Wagner"
        },
        {
            "name": "Andrew Willis"
        },
        {
            "name": "Matthias Miller"
        },
        {
            "name": "Shawn Beus"
        },
        {
            "name": "Brady Wood"
        },
        {
            "name": "Robert Head"
        }
        ]
    },
    {
        "title": "Marketing and Promotion",
        "color": "#006838c7",
        "id": 75,
        "description": "The Utah SBDC understands the breadth and fragmentation of resources available to small business owners and prospective entrepreneurs. The network must establish itself as the leading source of assistance for small business and entrepreneurs and must stand out in a dynamic and crowded marketplace with a strong image, unmistakable message and the ability to provide a clearly defined array of services that can be tailored to meet the needs of a divergent client group.",
        "members": [{
            "name": "Mark Holmes"
        },
        {
            "name": "Brady Wood"
        },
        {
            "name": "Peter Callister"
        },
        {
            "name": "Andrew Willis"
        },
        {
            "name": "Robert Head"
        },
        {
            "name": "Cam Findlay"
        }
        ]
    },
    {
        "title": "Technology Utilization",
        "color": "#00A69Cc7",
        "id": 79,
        "description": "The Utah SBDC recognizes the positive impact of innovation and technology development on the economic vitality of the state. The network also recognizes the need to be innovative and to effectively deploy available technology to improve efficiency and effectiveness in targeting and delivering services to clients and help them improve their business operations.",
        "members": [{
            "name": "Aaron Griffiths"
        },
        {
            "name": "Mike Young"
        },

        {
            "name": "Jess Clifford"
        },
        {
            "name": "Matthias Miller"
        }

        ]
    },
    {
        "title": "Leadership & Collaboration",
        "color": "#1B75BBc7",
        "id": 72,
        "description": "The Utah SBDC recognizes the importance of maintaining balance among stakeholder priorities. The network should play a leadership role to actively enable collaboration and close communication with network partners, external partners and stakeholders to develop consistent delivery of relevant content and the use of all available resources to meet identified strategic objectives.",
        "members": [{
            "name": "Jess Clifford"
        },
        {
            "name": "Tim Chamberlain"
        },
        {
            "name": "Mark Holmes"
        },
        {
            "name": "Jeff Mather"
        },
        {
            "name": "Mike Finnerty"
        },
        {
            "name": "Janette Wagner"
        },
        {
            "name": "Cam Findlay"
        }

        ]
    },
    {
        "title": "Program Reporting / Systems Compliance",
        "color": "#BE1E2Dc7",
        "id": 73,
        "description": "The Utah SBDC recognizes the need to integrate new initiatives from the OSBDC and ASBDC Accreditation into the operations of the network. It also recognizes the importance of accurate reporting supported by sustainable systems.",
        "members": [{
            "name": "Joni Anderson"
        },
        {
            "name": "Mark Holmes"
        },
        {
            "name": "Jason Yerka"
        },
        {
            "name": "Camille Pendleton"
        }

        ]
    },
    {
        "title": "Capacity for Globalization",
        "color": "#F05A28c7",
        "id":  76,
        "description": "The Utah SBDC recognizes the importance for Utah firms to be competitive in a global economy. The network should play a leadership role in educating clients regarding the impact of globalization in all aspects of their business strategy, operations and growth.",
        "members": [{
            "name": "Jim Herrin"
        },
        {
            "name": "Tim Chamberlain"
        },
        {
            "name": "Jason Yerka"
        }


        ]
    }
]



// Other helper components...

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const sections = [
    { title: "Approach", key: "34" },
    { title: "Deployment", key: "35" },
    { title: "Learning", key: "36" },
    { title: "Integration", key: "37" },
];


const SectionDisplay = ({ title, content }) => (
    <>
        <Typography sx={{ fontSize: 20, fontWeight: 'bold', backgroundColor: '#f5f5f5', p:2  }}>
            {title}
        </Typography>
        <Divider sx={{mb: 1}}/>
        <Typography sx={{ my: 2 }} dangerouslySetInnerHTML={{ __html: content }} />
    </>
);

const StrategicObjective = ({ Index, Data, Objective }) => {


    const objectiveTitles = [5, 16, 19, 27, 31]
    const objectiveDescription = [8, 18, 21, 29, 33]
    const objectiveId = parseInt(Objective);
    const dataArr = Array.from(Data[Objective]);
    console.log(Data);

    return (
        <>
       
            <Typography sx={{ fontSize: 18 }}>
            {Index + 1}.    {Data[objectiveTitles[Index]]}
            </Typography>



            <Typography sx={{ my: 2 }}>
            <Typography sx={{ my: 2 }} dangerouslySetInnerHTML={{ __html: Data[objectiveDescription[Index]]}} />
            </Typography>

            <TableContainer component={Paper}>
                <Table sx={{ width: '100%' }} aria-label="simple table">
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
                            <TableCell align="left" sx={{ width: '50%' }}><Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>Activity</Typography></TableCell>
                            <TableCell align="left" sx={{ width: '25%' }}><Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>Targeted Dates</Typography></TableCell>
                            <TableCell align="left" sx={{ width: '25%' }}><Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>Status</Typography></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>



                        {dataArr.map((row, index) => (

                            <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >

                                <TableCell align="left"><Typography sx={{ fontSize: 12 }}>{row.Activity}</Typography></TableCell>
                                <TableCell align="left"><Typography sx={{ fontSize: 12 }}>{row['Targeted Date']}</Typography></TableCell>
                                <TableCell align="left"><Typography sx={{ fontSize: 12 }}>{row.Status}</Typography></TableCell>
                            </TableRow>
                        ))
                        }










                    </TableBody>
                </Table>



            </TableContainer>

            <Divider sx={{ pb: 5, mb: 5 }} />


        </>
    )
}



export const StrategicTeamsTeamPage = () => {
    const [formData, setFormData] = useState(null);
    const [objectives, setObjectives] = useState([]);
    const [value, setValue] = React.useState(0);
    const { id } = useParams(); // Get the team ID from the URL
    const WordpressToken = process.env.REACT_APP_WORDPRESS_TOKEN;
    const objectiveIds = ['7', '17', '20', '28', '32'];
    const [loading, setLoading] = useState(true);
    // Find the team in the strategicTeamMembers array
    const team = strategicTeamMembers.find(team => team.id === parseInt(id));

    useEffect(() => {
        setLoading(true); // Start loading
        axios.get(`https://utahsbdc.org/wp-json/gf/v2/entries/${id}`, {
            headers: {
                'Authorization': `Bearer ${WordpressToken}`
            }
        })
        .then(response => {
            setFormData(response.data);
            setObjectives(Array.from({ length: response.data['15'] }, (_, i) => {
                if (i < objectiveIds.length) {
                    return objectiveIds[i];
                }
                return i + 1;
            }));
            setLoading(false); // Data fetched, stop loading
        })
        .catch(error => {
            console.log(error);
            setLoading(false); // Error occurred, stop loading
        });
    }, [id]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    

    if (loading) {
        return (
            <PageLayout>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </div>
            </PageLayout>
        );
    }

    if (team != null) {
        return (
            <PageLayout>
                <div className="content-layout"> <Typography sx={{mt: 4, textTransform: 'capitalize', mb: 4, fontSize: 24 }}>
                        {team.title}
                    </Typography>

                    <Typography sx={{ fontSize: 16, mb: 4 }}>
                        {team.description}
                    </Typography>
    
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Box sx={{ width: '100%' }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                        <Tab label="Team Objectives" {...a11yProps(0)} />
                                    </Tabs>
                                </Box>
                                <TabPanel value={value} index={0}>
                                    {formData ? (
                                        <>
                                 

                                            <Typography sx={{ fontStyle: 'italic' }}>
                                                <p>Last Updated: {formData['date_updated']}</p>
                                            </Typography>

    
                                            {sections.map((section) => (
                                                <SectionDisplay key={section.key} title={section.title} content={formData[section.key]} />
                                            ))}

<Typography sx={{ fontSize: 20, fontWeight: 'bold', backgroundColor: '#f5f5f5', p:2  }}>
           Objectives
        </Typography>
        <Divider sx={{mb: 1}}/>
    
                                            {objectives.map((objective, index) => (
                                                <StrategicObjective key={index} Index={index} Objective={objective} Data={formData} />
                                            ))}
                                        </>
                                    ) : null}
                                </TabPanel>
                            </Box>
                        </CardContent>
                    </Card>
                </div>
            </PageLayout>
        );
    } else {
        return (
            <PageLayout>
                <div className="content-layout">
                    <Typography sx={{ textTransform: 'capitalize', mb: 2 }}>
                        Team not found.
                    </Typography>
                </div>
            </PageLayout>
        );
    }
};