import React from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';

const PartnerCard = ({ title, value, image }) => {
  const formatValue = (title, value) => {
    if (title === 'Capital' || title === 'Sales') {
      if (value >= 1000000) {
        return `$${(value / 1000000).toFixed(1)} Million`;
      } else {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
      }
    } else {
      return new Intl.NumberFormat('en-US').format(value);
    }
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  const cardStyles = {
    userSelect: 'none',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
  };

  return (
    <Card onContextMenu={handleContextMenu} style={cardStyles}>
      <CardMedia
        component="img"
        height="140"
        image={image}
        alt={title}
      />
      <CardContent>
        <Typography variant="h5" sx={{ textAlign: 'center', fontSize: 20 }} component="div">
          {title}
        </Typography>
        <Typography variant="body2" sx={{ textAlign: 'center', fontSize: 20, fontWeight: 'bold' }}>
          {formatValue(title, value)}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default PartnerCard;
