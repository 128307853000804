import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { PageLoader } from "./components/page-loader";
import { AuthenticationGuard } from "./components/authentication-guard";
import { AdminPage } from "./pages/admin-page";
import { CallbackPage } from "./pages/callback-page";
import { HomePage } from "./pages/home-page";
import { NotFoundPage } from "./pages/not-found-page";
import { ProfilePage } from "./pages/profile-page";
import { ProtectedPage } from "./pages/protected-page";
import { PublicPage } from "./pages/public-page";
import { Dashboard } from "./components/dashboard/Dashboard";
import { MainRoute } from "./mainRoute";
import { QueryClient, QueryClientProvider } from 'react-query';
const queryClient = new QueryClient();
export const MainWrapper = () => {
    const { isLoading, user } = useAuth0();

    if (isLoading) {
        return (
            <div className="page-layout">
                <PageLoader />
            </div>
        );
    } else {


        return (
            <>
                <QueryClientProvider client={queryClient}>
                    <Dashboard user={user}>
                        <MainRoute />


                    </Dashboard>
                </QueryClientProvider>
            </>
        )
    }




};

